import { Vue, Component } from "vue-property-decorator";
import { BCard, BButton } from "bootstrap-vue";
import { Social } from "@lib";
import AltIcon from "@/core/components/alt-icon";
import { SocialAction } from "../../auth/socials";

@Component({
    components: {
        BCard,
        BButton,
        AltIcon,
    },
})
export default class SettingsProfileSocials extends Vue {
    public socials: any = {
        yandex: false,
        vk: false,
        ok: false,
        google: false,
    };

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.init();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async init(): Promise<void> {
        const usecase = this.$alt.system.usecase.createAuthSocialsUseCase();
        this.socials = await usecase.loadSocialsInfo();
    }

    public async connectToYandex(): Promise<void> {
        return this.connect(Social.Yandex);
    }

    public async connectToVK(): Promise<void> {
        return this.connect(Social.VK);
    }

    public async connectToOK(): Promise<void> {
        return this.connect(Social.OK);
    }

    public async connectToGoogle(): Promise<void> {
        return this.connect(Social.Google);
    }

    private async connect(social: Social): Promise<void> {
        try {
            this.$alt.loader.show();

            const socialUri = this.$router.resolve({
                name: "socials",
                params: { action: SocialAction.Connection, social },
            }).href;
            const redirectUri = `${window.location.origin}${socialUri}`;

            const usecase = this.$alt.system.usecase.createAuthSocialsUseCase();
            const uri = await usecase.getAuthorizationUri(social, redirectUri);

            if (uri && uri.length > 0) {
                window.location = uri as any;
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public async disconnectFromYandex(): Promise<void> {
        return this.disconnect(Social.Yandex);
    }

    public async disconnectFromVK(): Promise<void> {
        return this.disconnect(Social.VK);
    }

    public async disconnectFromOK(): Promise<void> {
        return this.disconnect(Social.OK);
    }

    public async disconnectFromGoogle(): Promise<void> {
        return this.disconnect(Social.Google);
    }

    private async disconnect(social: Social): Promise<void> {
        try {
            const answer = await this.$alt.message.confirm(
                "Вы уверены, что хотите отключиться?",
                "Отключение от соцсети",
                { okText: "Отключиться" },
            );

            if (!answer) {
                return;
            }

            this.$alt.loader.show();

            const usecase = this.$alt.system.usecase.createAuthSocialsUseCase();
            await usecase.disconnect(social);
            await this.init();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
